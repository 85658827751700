import React from 'react';

function NotFound() {
  return (
    <div>
      <h2>Not Found</h2>
      <p>{'We couldn\'t find the page you\'re looking for.'}</p>
    </div>
  );
}
export default NotFound;
